

// js script hear
var $win = $(window);
// var winHeight = $win.height();
var winWidth = $win.width();


//Fixed bug in Browsers and devices of Bootstrap4
var fixbug = {
    init: function () {

        //Internet Explorer 10 in Windows Phone 8
        //Fix problem of viewport width in @-ms-viewport
        if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
            var msViewportStyle = document.createElement('style')
            msViewportStyle.appendChild(
                document.createTextNode(
                    '@-ms-viewport{width:auto!important}'
                )
            )
            document.head.appendChild(msViewportStyle)
        }

        //Android browser
        //Fix <select> not display the side controls dropdown
        $(function () {
            var nua = navigator.userAgent
            var isAndroid = (nua.indexOf('Mozilla/5.0') > -1 && nua.indexOf(
                'Android ') > -1 && nua.indexOf('AppleWebKit') > -1 && nua.indexOf(
                    'Chrome') === -1)
            if (isAndroid) {
                $('select.form-control').removeClass('form-control').css(
                    'width',
                    '100%')
            }
        })
    }
};


var sitemapToggle = {
    init: function () {
        if (winWidth > 1199) {
            $("#sitemap__button").click(function () {
                $(".header").toggleClass("sitemap__collapse");
                $("body").toggleClass("sitemap__collapse");
            });
        }
    }
};

var dropdownLink = {
    init: function () {
        // if (winWidth < 1200) {
        $('#sitemap > .navbar-nav .dropdown-toggle').click(function () {
            var location = $(this).attr('href');
            window.location.href = location;
            return false;
        });
        // }
    }
};

var viewportAnimate = {
    init: function () {
        $('[data-vp-animate]').each(function () {
            var classToAdd = 'visible animated ' + ($(this).data('vp-animate') ?
                $(this).data('vp-animate') : 'fadeIn');
            var offsetAdd = ($(this).data('vp-offset') ?
                $(this).data('vp-offset') : 100);
            $(this).addClass('disable').viewportChecker({
                classToAdd: classToAdd
            });
        });
    }
};

var sustainToggle = {
    init: function () {

        var buttonLeft = $("#buttonLeft");
        var buttonRight = $("#buttonRight");
        var leftSide = $("#leftSide");
        var rightSide = $("#rightSide");

        buttonLeft.on('click', function () {
            // Closed
            if (buttonLeft.attr("aria-expanded") === "false") {
                leftSide.removeClass('col-xl-8').removeClass('col-xl-4');
                rightSide.removeClass('col-xl-8').removeClass('col-xl-4');
                leftSide.addClass('col-xl-8');
                rightSide.addClass('col-xl-4');
                buttonRight.attr("aria-expanded", "false");
                $("#collapseCSR").removeClass("show");
            }
            // Opened
            if (buttonLeft.attr("aria-expanded") === "true") {
                leftSide.removeClass('col-xl-8').removeClass('col-xl-4');
                rightSide.removeClass('col-xl-8').removeClass('col-xl-4');
                leftSide.addClass('col-xl-4');
                rightSide.addClass('col-xl-8');
                buttonRight.attr("aria-expanded", "true");
                $("#collapseCSR").addClass("show");
            }
        });

        buttonRight.on('click', function () {
            // Closed
            if (buttonRight.attr("aria-expanded") === "false") {
                leftSide.removeClass('col-xl-8').removeClass('col-xl-4');
                rightSide.removeClass('col-xl-8').removeClass('col-xl-4');
                rightSide.addClass('col-xl-8');
                leftSide.addClass('col-xl-4');
                buttonLeft.attr("aria-expanded", "false");
                $("#collapseCG").removeClass("show");

            }
            // Opened
            if (buttonRight.attr("aria-expanded") === "true") {
                leftSide.removeClass('col-xl-8').removeClass('col-xl-4');
                rightSide.removeClass('col-xl-8').removeClass('col-xl-4');
                rightSide.addClass('col-xl-4');
                leftSide.addClass('col-xl-8');
                buttonLeft.attr("aria-expanded", "true");
                $("#collapseCG").addClass("show");

            }
        });

    }
};

var timeline = $('.owl-timeline');
var timelineBox = $('.timeline__box');


var owlCarousel = {

    getTransform: function (el) {
        var results = $(el).css('-webkit-transform').match(/matrix(?:(3d)\(\d+(?:, \d+)*(?:, (\d+))(?:, (\d+))(?:, (\d+)), \d+\)|\(\d+(?:, \d+)*(?:, (\d+))(?:, (\d+))\))/)

        if (!results) return [0, 0, 0];
        if (results[1] == '3d') return results.slice(2, 5);

        results.push(0);
        return results.slice(5, 8);
    },
    keyMilestone: function () {
        timeline.owlCarousel({
            item: 3,
            margin: 0,
            nav: true,
            touchDrag: false,
            mouseDrag: false,
            animateOut: 'slideOutUp',
            animateIn: 'slideInUp',
            navText: [
                "",
                ""
            ],
            responsive: {
                0: {
                    item: 1
                },
            }


        });
        // var heights = $(".owl-stage-outer").map(function () {
        //     return $(this).height();
        // }).get(),

        //     maxHeight = Math.max.apply(null, heights);
        //     console.log(maxHeight);
        var itemCount = $('.owl-timeline.timeline .owl-item').length;
        var maxHeight = $('.owl-stage-outer').css('max-height');
        var itemHeight = maxHeight.match(/\d+/)[0] / 3;
        var sumHeight = itemHeight * (itemCount - 5);
        var translate3dNow = 0;
        $('.owl-item').css('height', itemHeight); // height element item
        $('.owl-stage').css('width', 'auto');
        $('.owl-stage').css('height', sumHeight); //height wrapper sum height item
        $(".owl-next").click(function () {

            if (translate3dNow > (-1 * (sumHeight))) {
                translate3dNow = translate3dNow - itemHeight;
                $('.owl-stage').css({
                    transform: "translate3d(0," + translate3dNow + "px, 0)"
                })
            }
            else {
                $('.owl-stage').css({
                    transform: "translate3d(0," + (-1 * (sumHeight)) + "px, 0)"
                })
            }

        });

        $(".owl-prev").click(function () {
            console.log(translate3dNow);
            if (translate3dNow >= 0) {
                console.log('over');
                translate3dNow = 0;
                $('.owl-stage').css({
                    transform: "translate3d(0," + translate3dNow + "px, 0)"
                })
            }
            if (translate3dNow < 0) {
                translate3dNow = translate3dNow + itemHeight;
                $('.owl-stage').css({
                    transform: "translate3d(0," + translate3dNow + "px, 0)"
                })

            }




        });
        timeline.on('initialized.owl.carousel', function (event) {
            $('.owl-prev').addClass('hidden-down');

        })

        $('.timeline' + ' .item').on('translate.owl.carousel', function () {
            var slideYear = $(this).data('year');
            var slideIndex = $('.timeline' + ' .item[data-year="' + slideYear + '"]').parent().index();
            $('.timeline').trigger('to.owl.carousel', slideIndex);

        });
        timeline.on('changed.owl.carousel', function (e) {
            var element = e.target;         // DOM element, in this example .owl-carousel
            var name = e.type;           // Name of the event, in this example dragged
            var namespace = e.namespace;      // Namespace of the event, in this example owl.carousel
            var items = e.item.count;     // Number of items
            var item = e.item.index;     // Position of the current item
            // Provided by the navigation plugin
            var pages = e.page.count;     // Number of pages
            var page = e.page.index;     // Position of th  e current page
            var size = e.page.size;      // Number of items per page
            var currItem = e.item.index;

            var lineData = $(e.target).find(".owl-item .timeline__box").eq(currItem).attr('data-target');

            var $element1Slide = $('.owl-item .timeline__box[data-target="' + lineData + '"]');

            $('.timeline__box').removeClass('center');

            $element1Slide.addClass('center');
            if (item == 0) { // first
                $('.owl-prev').addClass('hidden-down');
                $('.owl-next').removeClass('hidden-down');
            } else if (item == items - 1) { //last
                $('.owl-next').addClass('hidden-down');
                $('.owl-prev').removeClass('hidden-down');
            } else {
                $('.owl-next').removeClass('hidden-down');
                $('.owl-prev').removeClass('hidden-down');
            }
        });
        $('.timeline .owl-item').on('click', function () {
            $('.timeline__box').removeClass('center');
            $(this).find(".timeline__box").addClass('center');

        });
    },

    init: function () {
        if (timeline.length) {
            this.keyMilestone();
        }
    }
};

var billboard = {
    init: function () {

        $('.billboard').bxSlider({
            mode: 'fade',
            captions: true,
            auto: true,
            pager: false

        });
    }

}
var projectmap = {
    init: function () {

    }

}
//
var $googlemap = $('.google-map');
var $googlemapDetail = $('.google-map__detail');
var googleMap = {
    initMap: function (id, locations, zoom) {
        var map = new google.maps.Map(document.getElementById(id), {
            zoom: zoom,
            scrollwheel: false,
            center: {
                lat: locations[0][1],
                lng: locations[0][2]
            }
        });
        var image = ""; 
        var infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, 5)
        });
        var i = 0;

        for (i = 0; i < locations.length; i++) {
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                map: map,
                icon: "/storage/icon/"+locations[i][3]+".png"
            });

            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {

                    infowindow.setContent(locations[i][0]);
                    infowindow.open(map, marker);

                }
            })(marker, i));
        }

        infowindow.setContent(locations[0][0]);
        infowindow.open(map, marker);
    },
    setLocation: function (id) {
        var locations = new Array();
        id.each(function () {

            var data_id = $(this).attr("class");
            if ($googlemapDetail.length) {
                var data_name = $('.google-map__infowindow').html();
            }
            else{
                var data_name = $(this).data("name");
            }

            var data_lat = $(this).data("lat");
            var data_long = $(this).data("long");
            var data_process = $(this).data("process");
            if(data_process == undefined){
                data_process = "completed";
            }
            locations.push([data_name,data_lat,data_long,data_process]);

        });
        return locations;
    },
    setMap: function () {
        if ($googlemap.length) {
            $googlemap.each(function () {
                var id = $(this).attr("id");
                var location = $(this).data("location");
                var zoom = $(this).data("zoom");
                if(zoom == undefined){
                    zoom = 17 ;
                }
                if (location != null) {
                    var locations = googleMap.setLocation($(location).find(">span"));
                    googleMap.initMap(id, locations, zoom);
                }
            });
        }
    },
    init: function () {
        this.setMap();

    }
};
var datePicker = {
    historical_price: function () {
        $("#startdate,#enddate").datepicker(
            {
                dateFormat: 'dd/mm/yy',
                minDate: '-2Y',
                maxDate: '-1d',
                defaultdate: '-1d',
                changeMonth: true,
                changeYear: true
            }
        );
    },
    init: function () {
        this.historical_price();
    }
};
var popuplink = {
    init: function () {
        $('.video').magnificPopup({
            type: 'iframe',
            iframe: {
                patterns: {
                    dailymotion: {
                        //index: 'dailymotion.com',
                        id: function (url) {
                            var m = url.match(/^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/);
                            if (m !== null) {
                                if (m[4] !== undefined) {
                                    return m[4];
                                }
                                return m[2];
                            }
                            return null;
                        },

                        //src: 'https://www.dailymotion.com/embed/video/%id%'

                    }
                }
            }


        });
        $('.card-popup-link').magnificPopup({
            type: 'image'
            // other options
        });
    }
}

var buildEmail = {
    init: function () {
        if ($("#emailApplyJob").length) {
            var emailName =  $("#emailApplyJob").data("name");
            var emailServer = $("#emailApplyJob").data("mailserver");
            $("#emailApplyJob").text(emailName + emailServer);
        }
    }
}

var privacyStatement = {
    init: function () {
        let $checkbox = $('input[name="privacyStatement"]');
		let $btn = $('button[type="submit"]');

        if($btn.length && $checkbox.length) {
            $checkbox.click(function () {
                if($(this).prop('checked')) {
                    $btn.removeAttr('disabled');
                } else {
                    $btn.attr('disabled', 'disabled');
                }
            })
        }
    }

}

$(document).ready(function () {
    sitemapToggle.init();
    fixbug.init();
    datePicker.init();
    dropdownLink.init();
    viewportAnimate.init();
    sustainToggle.init();
    billboard.init();
    googleMap.init();
    owlCarousel.init();
    popuplink.init();
    buildEmail.init();
    privacyStatement.init();
});

