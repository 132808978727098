
//css
import './scss/index.scss'
import './scss/ir/index.scss'
import './fonts/font-face.css'
import './fonts/icon/style.css'


//script
// import './js/highchart/shareholders'
// import './js/highchart/shareholders-th'
//import './js/highchart/financial-highlight'
//import './js/highchart/financial-highlight-th'
import './js/utility'
/*

import './js/fotorama'
import './js/navigation-fn'
import './js/owl-client'
import './js/company-milestone'
import './js/viewport-animate'
import './js/google-map'
import './js/video-gallery'
import './js/file'

*/
